<template>
  <div class="container">

    <div class="mt-4 mb-4">
      <h4 class="text-center">Дисциплины рабочих учебных планов</h4>
      <DataTable :value="curriculumDisciplines_form.disciplinesApproved" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 v-model:filters="filters" filterDisplay="menu"
                 :globalFilterFields="['semester', 'study_course', 'name', 'code']">
        <template #header>
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск" class="input_search"/>
              </span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="p-d-flex p-jc-between p-ai-center">
                  <select class="form-control form-select" id="filterFilterDepartments"
                          @change="changeFilterDepartments">
                    <option v-for="(item, index) in departments"
                            :value="item.id"
                            :key="index"
                            :selected="item.id==departmentID">{{ item.name_ru }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </template>


        <Column field="code" header="Код"></Column>
        <Column header="Название" field="name">
          <template #body="{data}">
            <router-link
                :to="+data.is_selectable === 1 ? `/bind-selectable-groups?formation_education_program_id=${data.formation_education_program_id}&education_curriculum_id=${data.education_curriculum_id}`
                : `/binding-to-curriculum-new?discipline_id=${data.discipline_id}&semester=${data.semester}&academic_year=${data.sp_academic_year_id}`">
              {{ data.name }}
            </router-link>

          </template>
        </Column>
        <Column field="native_name" header="Язык"></Column>
        <Column field="semester" header="Семестер" filterField="semester">
          <template #filter="{filterModel}">
            <InputNumber v-model="filterModel.value"/>
          </template>
        </Column>
        <Column field="study_course" header="Курс" filterField="study_course">
          <template #filter="{filterModel}">
            <InputNumber v-model="filterModel.value"/>
          </template>
        </Column>
      </DataTable>

    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "CurriculumDisciplinesNew",

  data() {
    return {
      departmentID: +this.$route.query.department_id || 0,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'semester': {value: null, matchMode: FilterMatchMode.EQUALS},
        'study_course': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
    }
  },
  computed: {
    ...mapState('curriculumDisciplines', ['curriculumDisciplines_form']),
    ...mapState('vacancy', ['departments']),
    getDepartmentId() {
      return getCookie('DEPARTMENT_ID') || 6
    }
  },

  methods: {
    ...mapActions('curriculumDisciplines', ['GET_CURRICULUM_DISCIPLINES_APPROVED']),
    ...mapActions('vacancy', ['GET_DEPARTMENTS']),
    async changeFilterDepartments(e) {
      this.departmentID = e.target.value
      await this.changePage()
    },
    async changePage() {
      this.$router.push(`${this.$route.path}?department_id=${this.departmentID}`)
      await this.GET_CURRICULUM_DISCIPLINES_APPROVED(this.departmentID)
    },

  },
  async mounted() {
    this.departmentID = this.departmentID || this.getDepartmentId
    await this.GET_DEPARTMENTS()
    await this.GET_CURRICULUM_DISCIPLINES_APPROVED(this.departmentID)
  }
}
</script>

<style scoped>
.input_search {
  width: 80%
}
</style>